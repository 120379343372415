const resulteffects = {
  'Read Aloud': {
    tabs: { speaking: 1.0, reading: 1.0, oralFluency: 1.0, pronunciation: 1.0 },
    buildEffect: values => {
      //values, tabs'in doldurulmus hali
      let resultEffect = {
        effects: { speaking: values.speaking, reading: values.reading },
        itemEffects: {
          oralFluency: values.oralFluency,
          pronunciation: values.pronunciation
        }
      };
      return resultEffect;
    },
    buildTabs: value => {
      return {
        speaking: value.effects.speaking,
        reading: value.effects.reading,
        oralFluency: value.itemEffects.oralFluency,
        pronunciation: value.itemEffects.pronunciation
      };
    }
  },
  'Repeat Sentence': {
    tabs: { speaking: 1.0, listening: 1.0, oralFluency: 1.0, pronunciation: 1.0 },
    buildEffect: values => {
      let resultEffect = {
        effects: { speaking: values.speaking, listening: values.listening },
        itemEffects: {
          oralFluency: values.oralFluency,
          pronunciation: values.pronunciation
        }
      };
      return resultEffect;
    },
    buildTabs: value => {
      return {
        speaking: value.effects.speaking,
        listening: value.effects.listening,
        oralFluency: value.itemEffects.oralFluency,
        pronunciation: value.itemEffects.pronunciation
      }
    }
  },

  'Describe Image': {
    tabs: { speaking: 1.0, oralFluency: 1.0, pronunciation: 1.0 },
    buildEffect: values => {
      let resultEffect = {
        effects: { speaking: values.speaking },
        itemEffects: {
          oralFluency: values.oralFluency,
          pronunciation: values.pronunciation
        }
      };
      return resultEffect;
    },
    buildTabs: value => {
      return {
        speaking: value.effects.speaking,
        oralFluency: value.itemEffects.oralFluency,
        pronunciation: value.itemEffects.pronunciation
      }
    }
  },

  'Re-tell Lecture': {
    tabs: { speaking: 1.0, listening: 1.0, oralFluency: 1.0, pronunciation: 1.0 },
    buildEffect: values => {
      let resultEffect = {
        effects: { speaking: values.speaking, listening: values.listening },
        itemEffects: {
          oralFluency: values.oralFluency,
          pronunciation: values.pronunciation
        }
      };
      return resultEffect;
    },
    buildTabs: value => {
      return {
        speaking: value.effects.speaking,
        listening: value.effects.listening,
        oralFluency: value.itemEffects.oralFluency,
        pronunciation: value.itemEffects.pronunciation
      }
    }
  },

  'Answer Short Question': {
    tabs: { speaking: 1.0, listening: 1.0, vocabulary: 1.0 },
    buildEffect: values => {
      let resultEffect = {
        effects: { speaking: values.speaking, listening: values.listening },
        subEffects: {
          vocabulary: {
            scoreLabels: ['content'],
            max: 1,
            effect: values.vocabulary
          }
        }
      };
      return resultEffect;
    },
    buildTabs: value => {
      return {
        speaking: value.effects.speaking,
        listening: value.effects.listening,
        vocabulary: value.subEffects.vocabulary.effect
      }
    }
  },

  'Summarize Written Text': {
    tabs: { writing: 1.0, reading: 1.0, grammar: 1.0, vocabulary: 1.0 },
    buildEffect: values => {
      let resultEffect = {
        effects: { writing: values.writing, reading: values.reading },
        itemEffects: { grammar: values.grammar, vocabulary: values.vocabulary }
      };
      return resultEffect;
    },
    buildTabs: value => {
      return {
        writing: value.effects.writing,
        reading: value.effects.reading,
        grammar: value.itemEffects.grammar,
        vocabulary: value.itemEffects.vocabulary
      }
    }
  },

  'Write Essay': {
    tabs: {
      writing: 1.0,
      grammar: 1.0,
      spelling: 1.0,
      vocabulary: 1.0,
      writtenDiscourse: 1.0
    },
    buildEffect: values => {
      let resultEffect = {
        effects: { writing: values.writing },
        itemEffects: {
          grammar: values.grammar,
          spelling: values.spelling,
          vocabulary: values.vocabulary
        },
        subEffects: {
          writtenDiscourse: {
            scoreLabels: ['development', 'linguistic'],
            max: 4,
            effect: values.writtenDiscourse
          }
        }
      };
      return resultEffect;
    },
    buildTabs: value => {
      return {
        writing: value.effects.writing,
        grammar: value.itemEffects.grammar,
        spelling: value.itemEffects.spelling,
        vocabulary: value.itemEffects.vocabulary,
        writtenDiscourse: value.subEffects.writtenDiscourse.effect
      }
    }
  },

  'Multiple Choice, Choose Single Option': {
    tabs: { reading: 1.0 },
    buildEffect: values => {
      let resultEffect = {
        effects: { reading: values.reading }
      };
      return resultEffect;
    },
    buildTabs: value => {
      return {
        reading: value.effects.reading
      }
    }
  },

  'Multiple Choice, Choose Multiple Option': {
    tabs: { reading: 1.0 },
    buildEffect: values => {
      let resultEffect = {
        effects: { reading: values.reading }
      };
      return resultEffect;
    },
    buildTabs: value => {
      return {
        reading: value.effects.reading
      }
    }
  },

  'Re-order Paragraph': {
    tabs: { reading: 1.0 },
    buildEffect: values => {
      let resultEffect = {
        effects: { reading: values.reading }
      };
      return resultEffect;
    },
    buildTabs: value => {
      return {
        reading: value.effects.reading
      }
    }
  },

  'Fill In The Blanks': {
    tabs: { reading: 1.0 },
    buildEffect: values => {
      let resultEffect = {
        effects: { reading: values.reading }
      };
      return resultEffect;
    },
    buildTabs: value => {
      return {
        reading: value.effects.reading
      }
    }
  },

  'Selected Fill In The Blanks': {
    tabs: { reading: 1.0, writing: 1.0 },
    buildEffect: values => {
      let resultEffect = {
        effects: { reading: values.reading, writing: values.writing }
      };
      return resultEffect;
    },
    buildTabs: value => {
      return {
        reading: value.effects.reading,
        writing: value.effects.writing
      }
    }
  },

  'Highlight Correct Summary': {
    tabs: { listening: 1.0, reading: 1.0 },
    buildEffect: values => {
      let resultEffect = {
        effects: { listening: values.listening, reading: values.reading }
      };
      return resultEffect;
    },
    buildTabs: value => {
      return {
        listening: value.effects.listening,
        reading: value.effects.reading
      }
    }
  },

  'Summarize Spoken Text': {
    tabs: { listening: 1.0, writing: 1.0, grammar: 1.0, spelling: 1.0, vocabulary: 1.0 },
    buildEffect: values => {
      let resultEffect = {
        effects: { listening: values.listening, writing: values.writing },
        itemEffects: {
          grammar: values.grammar,
          spelling: values.spelling,
          vocabulary: values.vocabulary
        }
      };
      return resultEffect;
    },
    buildTabs: value => {
      return {
        listening: value.effects.listening,
        writing: value.effects.writing,
        grammar: value.itemEffects.grammar,
        spelling: value.itemEffects.spelling,
        vocabulary: value.itemEffects.vocabulary
      }
    }
  },

  'Multiple Choice, Choose Multiple Answer': {
    tabs: { listening: 1.0 },
    buildEffect: values => {
      let resultEffect = {
        effects: { listening: values.listening }
      };
      return resultEffect;
    },
    buildTabs: value => {
      return {
        listening: value.effects.listening
      }
    }
  },

  'Write In The Blanks': {
    tabs: { listening: 1.0, writing: 1.0 },
    buildEffect: values => {
      let resultEffect = {
        effects: { listening: values.listening, writing: values.writing }
      };
      return resultEffect;
    },
    buildTabs: value => {
      return {
        listening: value.effects.listening,
        writing: value.effects.writing
      }
    }
  },

  'Multiple Choice, Choose Single Answer': {
    tabs: { listening: 1.0 },
    buildEffect: values => {
      let resultEffect = {
        effects: { listening: values.listening }
      };
      return resultEffect;
    },
    buildTabs: value => {
      return {
        listening: value.effects.listening
      }
    }
  },

  'Select Missing Word': {
    tabs: { listening: 1.0 },
    buildEffect: values => {
      let resultEffect = {
        effects: { listening: values.listening }
      };
      return resultEffect;
    },
    buildTabs: value => {
      return {
        listening: value.effects.listening
      }
    }
  },

  'Highlight Incorrect Words': {
    tabs: { listening: 1.0, reading: 1.0 },
    buildEffect: values => {
      let resultEffect = {
        effects: { listening: values.listening, reading: values.reading }
      };
      return resultEffect;
    },
    buildTabs: value => {
      return {
        listening: value.effects.listening,
        reading: value.effects.reading
      }
    }
  },

  'Write From The Dictation': {
    tabs: { listening: 1.0, writing: 1.0 },
    buildEffect: values => {
      let resultEffect = {
        effects: { listening: values.listening, writing: values.writing }
      };
      return resultEffect;
    },
    buildTabs: value => {
      return {
        listening: value.effects.listening,
        writing: value.effects.writing
      }
    }
  }
};
module.exports.resultEffects = resulteffects;
