<template>
  <div>
    <el-form
      :label-position="labelPosition"
      :label-width="labelWidthForm"
      :model="model"
      :rules="rules"
      @submit.native.prevent="doSubmit"
      class="form"
      ref="form"
      :class="checkRTL"
      v-if="model"
    >
      <el-form-item
        :label="fields.questionType.label"
        :prop="fields.questionType.name"
        :required="fields.questionType.required"
      >
        <el-col :lg="11" :md="16" :sm="24">
          <app-question-type-autocomplete-input
            :fetchFn="fields.questionType.fetchFn"
            :mapperFn="fields.questionType.mapperFn"
            v-model="model[fields.questionType.name]"
            @input="qTypeChange"
            mode="single"
          ></app-question-type-autocomplete-input>
        </el-col>
      </el-form-item>

      <el-form-item
        :label="fields.structItemGroups.label"
        :prop="fields.structItemGroups.name"
        :required="fields.structItemGroups.required"
      >
        <el-col :lg="11" :md="16" :sm="24">
          <app-question-group-autocomplete-input
            :fetchFn="fields.structItemGroups.fetchFn"
            :mapperFn="fields.structItemGroups.mapperFn"
            v-model="model[fields.structItemGroups.name]"
            mode="multiple"
          ></app-question-group-autocomplete-input>
        </el-col>
      </el-form-item>

      <el-form-item
        :label="fields.count.label"
        :prop="fields.count.name"
        :required="fields.count.required"
      >
        <el-col :lg="11" :md="16" :sm="24">
          <el-input-number
            :precision="0"
            :step="1"
            v-model="model[fields.count.name]"
          ></el-input-number>
        </el-col>
      </el-form-item>

      <el-form-item
        :label="fields.resultEffect.label"
        :required="true"
        v-if="tabs"
      >
        <el-col :lg="11" :md="16" :sm="24">
          <el-tabs v-model="Object.keys(tabs)[0]" class="structure-tabs">
            <el-tab-pane
              v-for="(value, name) in tabs"
              :key="name"
              :label="getEffectsTabsLabel(name)"
              :name="name"
            >
              <el-input-number
                v-model="tabs[name]"
                :precision="1"
              ></el-input-number>
            </el-tab-pane>
          </el-tabs>
        </el-col>
      </el-form-item>

      <el-form-item :label="fields.descriptions.label" :required="true">
        <el-col :lg="13" :md="16" :sm="24">
          <el-tabs
            tab-position="right"
            style="height: 120px;"
            class="structure-tabs"
          >
            <el-tab-pane
              v-for="item in desclangs"
              :key="item"
              :label="fields['desc' + item].label"
            >
              <el-input
                type="textarea"
                rows="5"
                v-model="model[fields['desc' + item].name]"
              ></el-input>
            </el-tab-pane>
          </el-tabs>
        </el-col>
      </el-form-item>
      <el-form-item>
        <div class="form-buttons" :class="rtlAndPadding">
          <el-button
            @click="doSubmit"
            icon="el-icon-fa-floppy-o"
            type="primary"
          >
            <span class="card-title">
              <app-i18n code="common.save"></app-i18n>
            </span>
          </el-button>

          <el-button @click="doReset" icon="el-icon-fa-undo">
            <span class="card-title">
              <app-i18n code="common.reset"></app-i18n>
            </span>
          </el-button>

          <el-button @click="doCancel" icon="el-icon-fa-close">
            <span class="card-title">
              <app-i18n code="common.cancel"></app-i18n>
            </span>
          </el-button>
        </div>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { FormSchema } from '@/shared/form/form-schema';
import { StructItemModel } from '@/modules/structure/struct-item-model';
import { resultEffects } from '@/modules/structure/result-effects';
import { i18nWL } from '@/i18n';
import { i18n } from '@/i18n';
import questionTypes from '@/shared/questionType';

const { fields } = StructItemModel;
const formSchema = new FormSchema([
  fields.id,
  fields.count,
  fields.resultEffect,
  fields.questionType,
  fields.structItemGroups,
  fields.descEn,
  fields.descAr,
  fields.descTr
]);

export default {
  name: 'app-struct-item-form',
  components: {},
  props: ['isEditing', 'record', 'modal'],

  data() {
    return {
      rules: formSchema.rules(),
      model: null,
      desclangs: ['En', 'Ar', 'Tr'],
      tabs: null,
      re: null,
      questionTypeName: ''
    };
  },

  created() {
    this.model = formSchema.initialValues(this.record || {});
  },

  mounted() {
    if (this.model[fields.questionType.name]) {
      if (this.record)
        this.getResultEffectsForEditing(this.record.questionType.questionType);
    }
  },

  computed: {
    ...mapGetters({
      labelPosition: 'layout/labelPosition',
      labelWidthForm: 'layout/labelWidthForm',
      checkRTL: 'structure/form/checkRTL',
      rtlAndPadding: 'structure/form/rtlAndPadding'
    }),

    fields() {
      return fields;
    }
  },

  methods: {
    doReset() {
      this.model = formSchema.initialValues(this.record);
    },
    doCancel() {
      this.$emit('cancel');
    },
    findQuestionType(qName) {
      for (let [key, value] of Object.entries(questionTypes)) {
        if (qName === value) return key;
      }
    },
    defaultDescriptions(qType, lang) {
      if (qType) {
        return i18nWL(`entities.onlineExam.types.${qType}.description`, lang);
      }
    },
    getDescriptions(value) {
      const qTypeName = this.findQuestionType(value.qName);
      for (let i = 0; i < this.desclangs.length; i++) {
        this.model[
          fields['desc' + this.desclangs[i]].name
        ] = this.defaultDescriptions(
          qTypeName,
          this.desclangs[i].toLowerCase()
        );
      }
    },
    getEffectsTabsLabel(name) {
      return i18n(`entities.examResult.fields.${name}`);
    },
    getResultEffectsForNew(value) {
      this.questionTypeName = value.qName;
      this.re = resultEffects[this.questionTypeName];
      this.tabs = this.re.tabs;
    },
    getResultEffectsForEditing(value) {
      this.questionTypeName = value;
      this.re = resultEffects[this.questionTypeName];
      this.tabs = this.re.buildTabs(JSON.parse(this.record.resultEffect));
    },

    qTypeChange(value) {
      this.getResultEffectsForNew(value);
      this.getDescriptions(value);
    },

    // setFloatData(data) {
    //   //console.log(data);
    // },

    async doSubmit() {
      this.model[fields.resultEffect.name] = this.re
        ? JSON.stringify(this.re.buildEffect(this.tabs))
        : {};
      try {
        await this.$refs.form.validate();
      } catch (error) {
        return;
      }

      const { id, ...values } = formSchema.cast(this.model);
      values.questionTypeName = this.questionTypeName;
      
      return this.$emit('submit', {
        id,
        values
      });
    }
  }
};
</script>
<style>
.structure-tabs .el-tabs__item {
  color: black !important;
}
.structure-tabs .el-tabs__header {
  margin-left: 6px !important;
}
.structure-tabs textarea {
  font-family: 'Helvetica Neue', Helvetica, 'PingFang SC', 'Hiragino Sans GB',
    'Microsoft YaHei', SimSun, sans-serif;
}
</style>
